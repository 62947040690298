import './App.css';
import { NavBar } from './components/navbar/NavBar';
import packagejson from '../package.json';
import { resumeData } from './data';
import { ResumePage } from './pages/resume/ResumePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LinksPage } from './pages/links/LinksPage';
import { ProjectsPage } from './pages/projects/ProjectsPage';
import { FooterComponent } from './components/component/footer/FooterComponent';

function App() {
  return (
    <div className="App">
      <NavBar resume={resumeData} version={packagejson.version} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ResumePage data={resumeData} />} />
          <Route path="links" element={<LinksPage />} />
          <Route path="projects" element={<ProjectsPage data={resumeData} />} />
        </Routes>
      </BrowserRouter>
      <FooterComponent resumeModel={resumeData} />
    </div>

  );
}

export default App;

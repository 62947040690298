import { ContentContainerModel } from "../../definitions";
import { ElementProps } from "./Elements";
import "./ContentElement.css";

export interface ContainerElementProps extends ElementProps {
  content?: ContentContainerModel;
}
export function ContainerElement(props: {content: ContentContainerModel}) {
  // const contentItems = props.content.items?.map(c => <ContentItemElement content={c} /> ?? '');
  return (
    <div className="ContainerElement">
      {/* // {contentItems} */}
      container
    </div>
  )
}
import { ContentItemText } from "../../definitions";
import { ElementProps } from "./Elements";
import "./ContentElement.css";

export interface TextElementProps extends ElementProps {
  content?: ContentItemText;
}
export function TextElement(props: {content: TextElementProps}) {
  // const contentItems = props.content.items?.map(c => <ContentItemElement content={c} /> ?? '');
  const content = props.content as ContentItemText
  return (
    <>{content.text}</>
  )
}
import { Component } from "react";
import { TagElement } from "../../components/element/TagElement";
import { ContentItemTag } from "../../definitions";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const githubTag: ContentItemTag = {
  text: "code",
  type: "tag"
};

export class LinksPage extends Component {

  render() {
    return <div>
      <div>
        <a href="https://github.com/calebjonasson">
          <FontAwesomeIcon icon={faGithub} />Github
        </a>
        <TagElement tag={githubTag} />
      </div>

    </div>;
  }
}
import { ResumeModel, ResumeSectionModel } from "./definitions";

export const SECTION_TITLE_PERSONAL_PROJECTS = "Personal Projects";
export const WORK_HISTORY_TITLE_LABEL = "Company Name";
export const WORK_HISTORY_SUB_TITLE_LABEL = "Company Team Name";
export const WORK_HISTORY_TITLE_DESCRIPTION_LABEL = "Job Title";


const workSection: ResumeSectionModel = {
  type: 'container',
  title: "Work History",
  subSectionTitleLabel: WORK_HISTORY_SUB_TITLE_LABEL,
  subSectionSubTitleLabel: WORK_HISTORY_SUB_TITLE_LABEL,
  subSectionTitleDescriptionLabel: WORK_HISTORY_TITLE_DESCRIPTION_LABEL,

  subsections: [{
    title: "Reseau Listings Ltd",
    // subTitle: "TEAM",
    sectionDescription: "Software Engineer",
    startDate: "June 2024",
    endDate: "present",
    items: [{
      type: '',
      title: "Overview",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Designing Services to integrate with realestate listing services and advertising platforms.'
        }]
      }
    }]
  }, {
    title: "Amazon Web Services",
    subTitle: "Predictive Scaling",
    sectionIcon: "assets/company/aws-logo-light.png",
    sectionDescription: "Software Development Engineer II",
    startDate: "Dec 2021",
    endDate: "June 2024",
    footer: {
      imageRow: [
        "assets/company/aws-autoscaling.svg",
        "assets/company/aws-sagemaker.svg",
        "assets/company/aws-sagemaker-model.svg",
        "assets/company/aws-ddb.svg",
        "assets/company/aws-cloudformation.svg"
      ]
    },
    items: [{
      type: '',
      title: "High Level Achievements",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: '- Shipped 3 large projects'
        }, {
          type: 'paragraph',
          text: '- Presented operational solutions to the EC2 Organization.'
        }, {
          type: 'paragraph',
          text: '- Promoted to L5'
        }, {
          type: 'paragraph',
          text: '- Participated in the Hiring Process'
        }, {
          type: 'paragraph',
          text: '- Designed Systems that process metric data that are utilized by thousands of customers to scale millions of compute capacity.'
        }, {
          type: 'paragraph',
          text: '- Increased forecasting accuracy by 57% for 80% of the customers.'
        }]
      }
    }, {
      title: "The Job",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Worked on the Predictive Scaling service team to build new features and capabilities for the EC2 AutoScaling - Predictive Scaling service. This service allows customers, like Stripe and Netflix; and internal AWS resources, like dynamodb and ec2; to proactively scale up resources instead of reactively scaling resources to ensure customers have the resources available when needed.",
          // text: "Designed and implemented solutions to improve operations, service forecasting accuracy and new features for customers."
        }, {
          type: 'paragraph',
          text: "Investigated operational issues, shared findings and improvements with the organization and worked on high level issue issues."
        }, {
          type: 'paragraph',
          text: "Dove deep into science papers and journals relating to models, measuring and timeseries forecasting."
        }, {
          type: 'link',
          text: 'AWS - Predictive Scaling',
          url: "https://aws.amazon.com/about-aws/whats-new/2023/01/amazon-ec2-auto-scaling-forecasts-frequently-predictive-scaling/"
        }]
      }
    }, {
      type: 'Project',
      title: "Frequent Forecasting",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Designed, developed and shipped the Frequent Forecast project for Predictive Scaling allowing our forecasts to run more frequently reducing MAPE.'
        }, {
          type: 'paragraph',
          text: '- Increased under-forecasting performance for customers by 21%.'
        }, {
          type: 'paragraph',
          text: '- Increased accuracy for 80% of customers by 57%.'
        }, {
          type: 'link',
          url: 'https://aws.amazon.com/about-aws/whats-new/2023/01/amazon-ec2-auto-scaling-forecasts-frequently-predictive-scaling/',
          text: 'AWS - Predictive Scaling Frequent Forecasting Announcement'
        }]
      }
    }, {
      type: 'Project',
      title: "Status change Event Processor",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Designed and implemented a service that tracked step function workflows allowing us to gather high cardinal metrics.'
        }, {
          type: 'paragraph',
          text: '- Enabled the team to monitor faults, errors and latency as percentile metrics.'
        }, {
          type: 'paragraph',
          text: '- Drastically increased the speed of root cause analysis.'
        }, {
          type: 'paragraph',
          text: '- Allowed the correlation of faults and errors with various systems.'
        }, {
          type: 'paragraph',
          text: '- Allowed for operation reports containing step function executions, retries and data.'
        },]
      }
    },
    // TODO: add the control plane throttling for emergency configurations project
    {
      type: 'Project',
      title: "Backtesting",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Worked on the back-testing project and shipped the core logic while also reducing the forecast execution time.'
        }, {
          type: 'paragraph',
          text: 'Allows customers to see if Predictive Scaling is a viable solution for their business by leveraging historical data from Cloudwatch.'
        }, {
          type: 'link',
          text: 'AWS - Time Series Forecasting Principals',
          url: 'https://docs.aws.amazon.com/whitepapers/latest/time-series-forecasting-principles-with-amazon-forecast/step-4-evaluate-predictors.html'
        }]
      }
    }, {
      type: 'Project',
      title: "GovCloud",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Shipped the Predictive Scaling service for our customers in Gov Cloud.'
        }, {
          type: 'link',
          text: 'AWS - Gov Cloud Announcement',
          url: 'https://aws.amazon.com/about-aws/whats-new/2022/05/amazon-ec2-auto-scaling-predictive-feature-aws-govcloud-us-west-region/'
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "AWS",
    }, {
      type: "tag",
      text: "CDK"
    }, {
      type: "tag",
      text: "Stepfunctions"
    }, {
      type: "tag",
      text: "microservices"
    }, {
      type: "tag",
      text: "Machine Learning"
    }, {
      type: "tag",
      text: "Forecasting"
    }, {
      type: "tag",
      text: "Java"
    }, {
      type: "tag",
      text: "TypeScript"
    }, {
      type: "tag",
      text: "SQS"
    }, {
      type: "tag",
      text: "DynamoDB"
    }, {
      type: "tag",
      text: "CloudWatch"
    }, {
      type: "tag",
      text: "Ops"
    }, {
      type: "tag",
      text: "Monitoring"
    }, {
      type: "tag",
      text: "Sage Maker"
    }, {
      type: "tag",
      text: "Lambda"
    }]
  }, {
    title: "Personal Fitness",
    // subTitle: ,
    // subTitleLabel: "Team Name",
    sectionIcon: "assets/company/personalfitness.png",
    sectionDescription: "Startup CEO & Founder",
    tags: [{
      type: "tag",
      text: "Startup"
    }, {
      type: "tag",
      text: "AWS"
    }, {
      type: "tag",
      text: "microservices"
    }, {
      type: "tag",
      text: "Marketing"
    }, {
      type: "tag",
      text: "Web Design"
    }, {
      type: "tag",
      text: "Angular"
    }, {
      type: "tag",
      text: "Material UI"
    }, {
      type: "tag",
      text: "RxJS"
    }, {
      type: "tag",
      text: "Springboot"
    }, {
      type: "tag",
      text: "Spring Cloud Config"
    }, {
      type: "tag",
      text: "MySQL"
    }, {
      type: "tag",
      text: "MongoDB"
    }],
    startDate: "May 2016 - Nov 2020",
    items: [{
      title: "About the Company",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Personal Fitness was a starup web application created from the ground up that was created to allow personal trainers to automate the management of clients, their team, assigned programs and workouts, communication between their team and clients via messaging and notifications, as well as sell services and programs through an online storefront via a stripe integration.'
        }, {
          type: 'paragraph',
          text: 'There was a gap in the products available that lead trainers to creating the same programs for a large amount of clients using tools like google sheets. Personal fitness was built to create a simple user experience for customers that allowed them to work through their workouts and update their completion status with feeling, reps and relative perceived exertion that empowered the trainer to quickly modify and adapt the program to meet their fitness goals and progress.'
        }]
      }

    }, {
      title: "Overview",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Did everything from raising money, hiring developers and designers, working with photographers and videographers, designing the user experience, and working as marketting; as well as doing the tech side of writing a microservice architecture to scale on Amazon Web Services.'
        }, {
          type: 'paragraph',
          text: "This was a huge growth point in my career as I had to balance the personal needs of employees while managing relations with trainers and being absolutely customer driven when designing features."
        }]
      }
    }, {
      title: "Frontend",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Wrote the front end using Angular 8 with Material UI for the mobile application and web application."
        }, {
          type: 'paragraph',
          text: "Content for the web side was provided via AWS Cloudfront."
        }, {
          type: 'paragraph',
          text: "leveraged angular services with rxjs to manage the state of the application using an action pattern to reproduce and test flows."
        }]
      }
    }, {
      title: "Backend",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "The backend was written in Java with Springboot. It used a Gateway/Control Plane with business planes and subsequent dataplanes."
        }, {
          type: 'paragraph',
          text: "Communication was implemented using Open Feign to handle circuit breakers, retry, and plugable communication."
        }, {
          type: 'paragraph',
          text: "Configuration was deployed using spring cloud config which allowed for easy roll-forward/roll-back of features."
        }, {
          type: 'paragraph',
          text: "Deployments were performed via Jenkins and Terraform with versioned containers that supported rolling forward and backwards with blue/green deployments."
        }]
      }
    }, {
      title: "Operations",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "The application over 2 years did not experience down time."
        }, {
          type: 'paragraph',
          text: "Leveraged Cloudwatch to create dashboarding and alarms to monitor the application, deployments, service operations, and business operations."
        }]
      }
    }]
  }, {
    title: "XGolf America Inc.",
    subTitle: "Core Services",
    sectionIcon: "assets/company/xgolf.svg",
    sectionDescription: "Principal Software Engineer",
    startDate: "June 2019",
    endDate: "Oct 2021",
    items: [{
      title: "Overview",
      content: {
        type: 'content',
        items: [{
          type: "paragraph",
          text: "I Lead the onboarding of a physical product to the cloud by planning, designing and implementing a scalable multi-service architecture for X-Golf America to move their 20+ franchise's golf simulators to be interconnected in the cloud and eventually expand their business to the now 120+ locations."
        }, {
          type: "paragraph",
          text: "This project included designing fault tolerant systems to allow customers to create accounts, track golf metrics like handicap and swing speed, allowed franchises to manage their simulators and bookings, and allowed XGolf to manage their franchises."
        }]
      }
    }, {
      title: "Build CI/CD",
      type: "content",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'I implemented a build system that automatically created containerized services on a merged PR that then would be built, pushed to AWS ECR, tested, deployed to AWS ECS and rolled back on failure of canaries. Traffic was slowly shifted to new nodes by default allowing for rapid development and quick alarming.'
        }]
      }
    }, {
      title: "Infrastructure",
      type: "content",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Configuration: Services leveraged Spring Cloud Config which allowed us to leverage versioned configurations, live reloads of service configurations and single environment variables to point the service to the configuration services.'
        }, {
          type: 'paragraph',
          text: 'Communication: Leveraged openfeign for simple connectivity between services, hysterix for circuit breakers and custom handlers for inter service communication errors.'
        }]
      }
    }, {
      title: "Devops",
      type: "content",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Leveraged cloudwatch alarms and dashboards to ensure the application and AWS services operated within set boundaries.'
        }, {
          type: 'paragraph',
          text: 'Wrote runbooks and playbooks to ensure that future operators were able to quickly triage and adopt the system.'
        }, {
          type: 'paragraph',
          text: 'All applications emitted metrics using micrometer and allowed for the tracking of system and business critical metrics.'
        }, {
          type: 'paragraph',
          text: 'Used common libraries and abstract Spring services to ensure uniformity in configurations, machine id generation, and log formatting.'
        }, {
          type: 'paragraph',
          text: 'Used terraform to manage deployed infrastructure and push change sets to production through pipelines.'
        }]
      }
    }, {
      title: "Service Features",
      type: "content",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Created the services to handle users/profiles and auth.'
        }, {
          type: 'paragraph',
          text: 'Created management APIs for XGolf employees to manage users, redeemable items, golf games, tournaments, simulators, franchises and more.'
        }, {
          type: 'paragraph',
          text: 'Created franchise management to allow franchises to manage employees, view audit records, book simulators and manage redeemable items, tournaments, and game data.'
        }, {
          type: 'paragraph',
          text: 'Created the game services to track all player events and game state information as well as metrics to allow for record keeping, player metric aggregation, score cards and tournament/league results.'
        }, {
          type: 'paragraph',
          text: 'Created a notification service to handle client notifications and email leveraging asynchronous batching.'
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "AWS",
    }, {
      type: "tag",
      text: "Java",
    }, {
      type: "tag",
      text: "ECS",
    }, {
      type: "tag",
      text: "Docker",
    }, {
      type: "tag",
      text: "OpenFeign",
    }, {
      type: "tag",
      text: "Application Load Balancers",
    }, {
      type: "tag",
      text: "Spring Cloud",
    }, {
      type: "tag",
      text: "Springboot",
    }, {
      type: "tag",
      text: "Devops",
    }, {
      type: "tag",
      text: "CI/CD",
    }, {
      type: "tag",
      text: "Terraform",
    }, {
      type: "tag",
      text: "Microservices",
    }],
    footer: {
      imageRow: [
        "assets/company/xgolf.svg"
      ]
    }
  }, {
    title: "Iron Stag Inc.",
    subTitle: "Software Consulting",
    sectionIcon: "assets/company/ironstag.png",
    sectionDescription: "CEO & Founder",
    startDate: "April 2017",
    endDate: "Dec 2021",
    items: [{
      title: "Overview",
      content: {
        type: 'content',
        items: [{
          type: "paragraph",
          text: "Iron Stag is a company I started to provide software consulting to business looking to migrate and scale in the cloud. I Provided architectural design, solutions, auditing and services to help business adapt and iterate quickly."
        }, {
          type: "paragraph",
          text: "Worked with technical and non technical business owners to earn trust, build relationships, and find solutions for them and their customers."
        }, {
          type: "paragraph",
          text: "Migrated multiple customer applications to AWS. Performed load testing, server, networking, application and database profiling to remove technical bottlenecks and validate that they are making the best business choices."
        }]
      }
    }, {
      title: "Customers",
      type: "",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "- RFG Software"
        }, {
          type: 'paragraph',
          text: "- Dreambox"
        }, {
          type: 'paragraph',
          text: "- XGolf America inc."
        }, {
          type: 'paragraph',
          text: "- The School Concierge"
        }, {
          type: 'paragraph',
          text: "- Heal Wellness Challenge"
        }]
      }
    }],
    footer: {
      imageRow: [
        "assets/company/rfg-logo.svg",
        "assets/company/dreambox.png",
        "assets/company/xgolf.svg",
        "assets/company/heal.png"
      ]
    }
  }, {
    title: "The Walt Disney Company",
    subTitle: "Guest Experience",
    sectionDescription: "Software Engineer",
    sectionIcon: "assets/company/disney-logo.png",
    startDate: "Nov 2010",
    endDate: "Apr 2016",
    items: [{
      type: "",
      title: "Overview",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "I worked on a small team where I was one of two backend software engineers that built, designed and maintained the software used by customer support reps."
        }, {
          type: 'paragraph',
          text: "The software we designed was used by 24/7 call centre staff around the world to moderate text/chat/user generated content/abuse as well as perform billing/remediation to support guests that called into with problems."
        }]
      }
      // content: []
    }, {
      type: "Project",
      title: "Visa Integration",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Worked with an external team, Visa, to query micro transactions, subscriptions, offer complimentary items and in game currency or memberships through the customer support platform.\nModelled transaction information to work with many payment providers (Google Wallet, Paypal) to be able to offer the same user experience in the tool depending on the product integration. (Starwars, Club Penguin, etc)'
        }]
      }
    }, {
      type: "Project",
      title: "Chat Moderation",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Designed and implemented a chat moderation support system that can query all of the chat from a user, and the chat from those in the same digital space across multiple Disney products.'
        }, {
          type: 'paragraph',
          text: 'The chat moderation system allows moderators to automatically escalate and generate reports to their team leader and from there to local law enforcement. These reports would would be generated using information about the user in questions as well as their chat history, login locations, ips, etc.'
        }]
      }
    }, {
      type: "Project",
      title: "Health Check Library",
      content: {
        type: 'content',
        items: [{
          type: "paragraph",
          text: "Designed and implemented a Spring annotation driven deep health-checking library that allows verbose breakdowns of system information."
        }, {
          type: 'paragraph',
          text: 'This gave techops the ability to see if a datasource, cache, service, or external API/Integration was throwing errors.'
        }]
      }
    }, {
      title: "Agnostic Account Service",
      type: "Project",
      content: {
        type: 'content',
        items: [{
          type: "paragraph",
          text: "Designed and implemented an agnostic account system that allowed products with differing account models to have their content supported in the moderation tools."
        }, {
          type: 'paragraph',
          text: 'This meant designing a future proof and highly configurable and expandable system that our customer support representatives can use to do things like search for accounts, refund payments, manage a players inventory or view a guest reservation at one of the parks.'
        }]
      }
    }, {
      title: "User Generated Content Moderation",
      type: "Project",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Designed and Implemented a solution that enabled our customer support reps to moderate any form of content. (text, image, video). The solution also allows our integrating customers to easily support multiple methods of communication and rules.'
        }, {
          type: 'paragraph',
          text: 'Architected and implemented a user generated content moderation tool that allowed our integration partners (Disney+, Disney.com, Starwars, ESPN, Disney Consumer Products) to moderate anything digital that we could think of. This was originally scoped to be an image moderation tool for Club Penguin but ended up being built to support many media types including images/video and text. Our integrating partners could use this tool for flexibly billed moderation of a forum comment that was flagged to a contest where users submit videos of their children singing a song from the movie Frozen.'
        }, {
          type: 'paragraph',
          text: 'Created the initial prototype for the help.disney.com website'
        }]
      }
    }, {
      title: "Disney.com prototype and Salesforce Integration",
      type: "Project",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Lead the technical investigation into moving from a custom help site solution and ticketing system to Salesforce.'
        }, {
          type: 'paragraph',
          text: 'Created the initial prototype for the help.disney.com website'
        }]
      }
    }, {
      title: "Security Compliance Project",
      type: 'Project',
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Worked with Disney Security mandates to implement multiple security requirements to handle encryption in transit and encryption at rest.'
        }, {
          type: 'paragraph',
          text: 'Created a data framework guidline that classified different types of PII and associated it with new encryption requirements.'
        }, {
          type: 'paragraph',
          text: 'Dove deep on encryption and Bouncy Castle and wrote a custom library that allowed annotation driven encryption and decryption at the controller and data access/entity level.'
        }, {
          type: 'paragraph',
          text: 'Designed a second layer of encryption below the HTTPs layer to handle further encryption between our web interface and backend.'
        }, {
          type: 'paragraph',
          text: 'Implemented a custom request filter and a completed a large refactoring effort for all rest APIs to utilize the new standard of communication.'
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "System Design"
    }, {
      type: "tag",
      text: "API Design"
    }, {
      type: "tag",
      text: "Spring Framework"
    }, {
      type: "tag",
      text: "Java"
    }, {
      type: "tag",
      text: "PHP"
    }, {
      type: "tag",
      text: "Google App Engine"
    }, {
      type: "tag",
      text: "Zend Framework"
    }, {
      type: "tag",
      text: "Memcached"
    }, {
      type: "tag",
      text: "Nginx"
    }],
    footer: {
      imageRow: [
        "assets/company/disneyplus.png",
        "assets/company/starwars.png",
        "assets/company/clubpenguin.png",
        "assets/company/espn.png",
        "assets/company/disneyparks.svg",
        "assets/company/marvel.svg"
      ]
    }
  }, {
    title: "Greater Than Technologies",
    // subTitle: "TEAM",
    sectionDescription: "Web Application Developer",
    // sectionIcon: "",
    startDate: "Oct 2009",
    endDate: "Nov 2010",
    items: [{
      title: 'Overview',
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Sole backend developer for multiple smaller projects and a larger project.'
        }, {
          type: 'paragraph',
          text: 'Worked with a standard LAMP stack.'
        }]
      }
    }, {
      title: "Share My 360",
      type: 'Project',
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Designed and coded a web application that is essentially a YouTube like experience for custom built virtual tours for properties and vehicles sales."
        }]
      }
    }, {
      title: "Thanks a Million",
      type: 'Project',
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Built an application that allowed people to purchase a 'thank you' token that they can enter on a website and track the history of the token and who has received it on a map."
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "PHP"
    }, {
      type: "tag",
      text: "LAMP"
    }, {
      type: "tag",
      text: "Jquery"
    }, {
      type: "tag",
      text: "HTML"
    }, {
      type: "tag",
      text: "CSS"
    }]
  }]

};

const interestSection: ResumeSectionModel = {
  type: 'container',
  title: "Interests",
  styleClassName: "interest-section",
  subsections: [{
    cols: 4,
    title: '',
    // subTitle: "",
    items: [{
      title: 'Languages',
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            type: 'tag',
            text: 'Java'
          }, {
            type: 'tag',
            text: 'Typescript'
          }, {
            type: 'tag',
            text: 'Python'
          }, {
            type: 'tag',
            text: 'Rust'
          }, {
            type: 'tag',
            text: 'PHP'
          }, {
            type: 'tag',
            text: 'C#'
          }]
        }]
      }
    }, {
      title: "Frameworks",
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            type: 'tag',
            text: 'Spring MVC/Boot'
          }, {
            type: 'tag',
            text: 'Micronaut'
          }, {
            type: 'tag',
            text: 'Zend'
          }, {
            type: 'tag',
            text: 'Flask'
          }, {
            type: 'tag',
            text: 'TensorFlow'
          }]
        }]
      }
    }, {
      title: "Datastores",
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            type: 'tag',
            text: 'MySQL'
          }, {
            type: 'tag',
            text: 'MongoDB'
          }, {
            type: 'tag',
            text: 'DynamoDB'
          }, {
            type: 'tag',
            text: 'Redis'
          }, {
            type: 'tag',
            text: 'Elastic'
          }, {
            type: 'tag',
            text: 'Influx DB'
          }]
        }]
      }
    }, {
      title: 'Communication',
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            type: 'tag',
            text: 'Kafka'
          }, {
            type: 'tag',
            text: 'Streams'
          }, {
            type: 'tag',
            text: 'Rest'
          }, {
            type: 'tag',
            text: 'gRPC'
          }, {
            type: 'tag',
            text: 'Web Sockets'
          }, {
            type: 'tag',
            text: 'SQS'
          }, {
            type: 'tag',
            text: 'Rabbit MQ'
          }]
        }]
      }
    }, {
      title: 'Operations',
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            type: 'tag',
            text: 'Grafana/Loki/Mimir/Promtail'
          }, {
            type: 'tag',
            text: 'Cloudwatch'
          }, {
            type: 'tag',
            text: 'Splunk'
          }]
        }]
      }
    }, {
      title: 'Front-End',
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            type: 'tag',
            text: 'React'
          }, {
            type: 'tag',
            text: 'Typescript'
          }, {
            type: 'tag',
            text: 'Angular'
          }, {
            type: 'tag',
            text: 'Rxjs'
          }]
        }]
      }
    }, {
      title: 'Other Tools',
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            text: 'Kubernetes',
            type: 'tag'
          }, {
            text: 'Containers: Docker/Containerd/LXC',
            type: 'tag'
          }, {
            text: 'Virtual Machines: QEMU/KVM',
            type: 'tag'
          }]
        }]
      }
    }, {
      title: 'Other Things',
      content: {
        type: 'content',
        items: [{
          type: 'tags',
          tags: [{
            text: 'Road Cycling',
            type: 'tag'
          }, {
            text: 'Wildlife and Astro-landscape Photography.',
            type: 'tag'
          }, {
            text: 'Guitars and Ukulele',
            type: 'tag'
          }, {
            text: 'Golf',
            type: 'tag'
          }]
        }]
      }
    }]
  }]
};
const personalProjectSection: ResumeSectionModel = {
  type: 'container',
  title: SECTION_TITLE_PERSONAL_PROJECTS,
  subsections: [{
    title: "Balto.run",
    // subTitle: "",
    sectionDescription: 'Distributed discovery, append-to-database, message broker and compute',
    startDate: '2023',
    endDate: 'present',
    items: [{
      title: "Overview",
      type: "Active",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Balto is a work in progress that allows for 'outposts' to discover each other, send messages, store them for a given time, and perform executions of custom commands."
        }, {
          type: 'paragraph',
          text: "Balto is currently private and hosted in multiple clouds (AWS, Azure, GCP)."
        }]
      }
    }, {
      title: "Features",
      type: "Features",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "- Node discovery and leader election."
        }, {
          type: 'paragraph',
          text: "- Distributed circle buffers to handle commands."
        }, {
          type: 'paragraph',
          text: "- Distributed short term journals for data storage."
        }, {
          type: 'paragraph',
          text: "- Distributed command execution."
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "Rust"
    }, {
      type: "tag",
      text: "Actix"
    }, {
      type: 'tag',
      text: 'Distributed Store'
    }, {
      type: 'tag',
      text: 'Discovery'
    }, {
      type: 'tag',
      text: 'tcp'
    }, {
      type: 'tag',
      text: 'custom protocol'
    }, {
      type: 'tag',
      text: 'Quorum'
    }, {
      type: 'tag',
      text: 'openssl'
    }, {
      type: 'tag',
      text: 'containerd'
    }]
  }, {
    title: "Coru.io",
    // subTitle: "TEAM",
    sectionDescription: 'Dynamic command execution available from relative paths.',
    startDate: '2024',
    endDate: 'present',
    items: [{
      title: "Overview",
      type: "Active",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Coru allows for relative discovery of coru manifest files based on the local path. Coru commands allow the user to tab auto complete and execute commands."
        }, {
          type: 'paragraph',
          text: "Commands are streamed back to the display or routed to an execution file."
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "Typescript"
    }, {
      type: "tag",
      text: "Node"
    }, {
      type: 'tag',
      text: 'cli'
    }, {
      type: 'tag',
      text: 'bash'
    }]
  }, {
    title: "Red Rock Framework",
    // subTitle: "",
    sectionDescription: 'Springboot based framework',
    startDate: '2017',
    endDate: 'present',
    items: [{
      title: "Overview",
      type: "Active",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Redrock is a Springboot based framework which has a series of maven projects that act as parents. These parent projects give very discrete features depending on the project used."
        }, {
          type: 'paragraph',
          text: "This framework project was created when rewriting the Personal Fitness web services. They include numerous libraries for pre build domains, ACL management/mappers, Machine ID generation. Internal Rate Limiting libraries as well as some other projects to help bootstrap projects."
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "Springboot"
    }, {
      type: "tag",
      text: "Zookeeper"
    }, {
      type: "tag",
      text: "Kafka"
    }, {
      type: "tag",
      text: "Elastic Search"
    }, {
      type: "tag",
      text: "MongoDB"
    }, {
      type: "tag",
      text: "Reacive Programming"
    }, {
      type: "tag",
      text: "Kubernetes"
    }]
  }, {
    title: "Home Lab/Private Cloud",
    // subTitle: "TEAM",
    startDate: "2005",
    endDate: "present",
    items: [{
      title: "Overview",
      content: {
        type: 'content',
        items: [{
          type: "paragraph",
          text: "Since I was a teenager I've always had a server running to learn linux that utilized old computers. It really started when I built a more powerful server to keep the Lirkr Search Engine/Crawler project going. Now this has evolved into a full blown hobby that has filled an 18u rack in my house with remote nodes at friends and families houses and in the cloud that allows me to tinker and learn."
        }, {
          type: "paragraph",
          text: "Personal Homelab/Private Cloud spread out over 3 cities and the public cloud."
        }, {
          type: "paragraph",
          text: "Consists of distributed Compute and storage with fencing."
        }]
      }
    }, {
      title: "Services",
      content: {
        type: 'content',
        items: [{
          type: "paragraph",
          text: "- File sharing/backup for family and friends"
        }, {
          type: "paragraph",
          text: "- General project hosting"
        }, {
          type: "paragraph",
          text: "- Gaming servers"
        }, {
          type: "paragraph",
          text: "- Authentication (forward auth/ldap)"
        }, {
          type: "paragraph",
          text: "- Custom intrusion detection/nmap scanning"
        }, {
          type: "paragraph",
          text: "- Runbooks and Dashboards"
        }]
      }
    }, {
      title: "Infrastructure",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "- 2 Network attached storage devices. (108TB usable ZFS raidz2, 46TB usable raid5)"
        }, {
          type: 'paragraph',
          text: "- 8 compute nodes (196 cores)"
        }, {
          type: 'paragraph',
          text: "- L2/3 networking (SFP10/28)"
        }, {
          type: 'paragraph',
          text: "- VPN interconnects",
        }, {
          type: 'paragraph',
          text: "- Live migrations (2-3 minutes of downtime if I start pulling power cables)"
        }, {
          type: 'paragraph',
          text: "- Ceph clustering for available SSD storage (18TB usable)"
        }, {
          type: 'paragraph',
          text: "- AMD 7950/Nvidia 4090 for machine learning workloads"
        }, {
          type: 'paragraph',
          text: "- 6 power bills a year that are totally worth it."
        }]
      }
    }, {
      title: "Networking",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: '- 802.1q (12+ Vlans)'
        }, {
          type: 'paragraph',
          text: '- Hardware and Virtualized Firewalls'
        }, {
          type: 'paragraph',
          text: '- Wireguard VPN'
        }, {
          type: 'paragraph',
          text: '- Core DNS/Unbound'
        }]
      }
    }, {
      title: "Provisioning",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: '- Deployment with Ansible and Terraform'
        }, {
          type: 'paragraph',
          text: '- Automated Patching'
        }, {
          type: 'paragraph',
          text: '- Netbox'
        }, {
          type: 'paragraph',
          text: '- SNMP'
        }, {
          type: 'paragraph',
          text: '- Gitlab'
        }, {
          type: 'paragraph',
          text: '- Jenkins'
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "Proxmox"
    }, {
      type: "tag",
      text: "Mikrotik"
    }, {
      type: "tag",
      text: "SFP28"
    }, {
      type: "tag",
      text: "Truenas"
    }, {
      type: "tag",
      text: "Ansible"
    }, {
      type: "tag",
      text: "Consul"
    }, {
      type: "tag",
      text: "Jsonnet"
    }, {
      type: "tag",
      text: "Kubernetes"
    }, {
      type: "tag",
      text: "Containerd"
    }, {
      type: "tag",
      text: "Helm"
    }, {
      type: "tag",
      text: "Grafana"
    }, {
      type: "tag",
      text: "Loki"
    }, {
      type: "tag",
      text: "Mimir"
    }, {
      type: "tag",
      text: "Promtail"
    }, {
      type: "tag",
      text: "Linux"
    }, {
      type: "tag",
      text: "InfluxDB"
    }, {
      type: "tag",
      text: "Yubico Yubikey"
    }, {
      type: "tag",
      text: "Vault"
    }, {
      type: "tag",
      text: "MFA"
    }]
  }, {
    title: "Foundations",
    // subTitle: "TEAM",
    sectionDescription: 'Dynamic Message Broker Processor',
    startDate: '2016',
    endDate: '2021',
    items: [{
      title: "Overview",
      type: "Retired",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: "Foundations Data Flow is a web crawler and data processing service originally written as a means of getting and transforming data for machine learning work flows."
        }, {
          type: 'paragraph',
          text: "It use the previously mentioned RedRock framework as a basis and uses emulated browsers and rest clients as a way of obtaining remote data. At it's heart the project uses Reactive processing to handle the flow of information using Kafka and direct streaming with back pressure."
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "Springboot"
    }, {
      type: "tag",
      text: "Springcloud"
    }, {
      type: "tag",
      text: "Zookeeper"
    }, {
      type: "tag",
      text: "Kafka"
    }, {
      type: "tag",
      text: "Consul"
    }, {
      type: "tag",
      text: "Elasticsearch"
    }, {
      type: "tag",
      text: "MongoDB"
    }, {
      type: "tag",
      text: "Kubernetes"
    }, {
      type: "tag",
      text: "Selenium"
    }, {
      type: "tag",
      text: "Redis"
    }, {
      type: "tag",
      text: "Grafana"
    }, {
      type: "tag",
      text: "React Native"
    }, {
      type: "tag",
      text: "GrahpQL"
    }, {
      type: "tag",
      text: "Hateos Rest"
    }]
  }, {
    title: "Lirkr",
    // subTitle: "TEAM",
    sectionDescription: 'Search Engine and News Aggregator',
    startDate: "2006",
    endDate: "2012",
    items: [{
      title: "Overview",
      type: "Retired",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Lirkr was a project that started when I was in high school as a chance to learn more about dealing with large data, data structures and search engines. I had previous exposure to technologies like Apache Lucene/Solr.'
        }, {
          type: 'paragraph',
          text: 'The project was PHP based and performed static crawling using many Dreamhost servers. It was a great lesson in how important caching, map reduce and batch operations.'
        }, {
          type: 'paragraph',
          text: 'Project was turned into a news client after the amount of data stored in Dreamhost crashed the shared MySQL server for many of their customers.'
        }, {
          type: 'paragraph',
          text: 'Crawling and playing with large multi-terabyte data sets continued on a private server.'
        }]
      }
    }, {
      title: "Details",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Crawled over 24 million domains.'
        }, {
          type: 'paragraph',
          text: 'Indexed over 4.8 TB of Data.'
        }, {
          type: 'paragraph',
          text: 'Wrote search algorithms that provided weights based on content classifications and neighbouring weights.'
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "PHP"
    }, {
      type: "tag",
      text: "Search Engine"
    }, {
      type: "tag",
      text: "MySQL"
    }, {
      type: "tag",
      text: "LAMP"
    }, {
      type: "tag",
      text: "Big Data"
    }],
    footer: {
      imageRow: []
    }
  }, {
    title: "IADBS",
    // subTitle: "TEAM",
    sectionDescription: "Internet Audio Database Service",
    startDate: "2006",
    endDate: "2010",

    items: [{
      title: "Overview",
      type: "Retired",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'The Internet Audio Database was a project that allowed users to discover new music by grouping similar artists and songs based what other users had grouped and liked. The project also allowed users to view lyrics, artist biographies and generate playlists from their Itunes library.xml file.'
        }]
      }
    }],
    "tags": [{
      type: "tag",
      text: "PHP"
    }, {
      type: "tag",
      text: "MySQL",
    }, {
      type: "tag",
      text: "LAMP"
    }]
  }, {
    title: "Skorg RSS",
    // subTitle: "TEAM",
    startDate: '2005',
    endDate: '2007',
    items: [{
      title: "Overview",
      type: "Retired",
      content: {
        type: 'content',
        items: [{
          type: 'paragraph',
          text: 'Skorg.org RSS was an application that allowed users to subscribe to a list of pre provided RSS Feeds or allow them to pull and customise how the rss feed is rendered.'
        }]
      }
    }],
    tags: [{
      type: "tag",
      text: "PHP"
    }, {
      type: "tag",
      text: "Perl"
    }, {
      type: "tag",
      text: "MySQL"
    }, {
      type: "tag",
      text: "Linux"
    }, {
      type: "tag",
      text: "NetBSD"
    }]
  }]
};

export const resumeData = {
  name: "Caleb Jonasson",
  pronouns: '',
  openToWork: true,
  location: 'Canada/Remote',
  links: {
    github: 'https://github.com/calebjonasson/',
    linkedin: 'https://www.linkedin.com/in/caleb-jonasson-86894b18/'
  },
  // rolesLookingFor: 'Full Time Remote Backend',
  headingContent: {
    items: [{
      type: 'heading',
      level: 1,
      text: 'Hello!'
    },
    // {
    //   type: 'heading',
    //   level: 2,
    //   text: 'Please allow me to introduce myself:'
    // },
    {
      type: 'heading',
      level: 2,
      text: 'My name is Caleb Jonasson,'
    },
    // {
    //   type: 'paragraph',
    //   text: 'My name is Caleb Jonasson,'
    // },
    {
      type: 'paragraph',
      text: 'I am a deeply technical software engineer with experience building loosely coupled systems that are scalable and maintainable.'
    }, {
      type: 'paragraph',
      text: 'I have had a successful career working in large companies and building software and services from the ground up to be low on technical debt that is scalable and agile. I have a passion for System Design, Database Internals, Messaging Systems, Operation Practices and Diving Deep on problems.'
    }, {
      type: 'paragraph',
      text: 'I\'m interested and getting products to market and learning new skills; while, building for the future.'
    }, {
      type: 'paragraph',
      text: 'Let\'s work together!'
    },
    {
      type: 'content',
      content: {
        items: [{
          type: 'tags',
          tags: [{
            type: 'tag',
            text: 'Available for Hire'
          }, {
            type: 'tag',
            text: 'Canada/Remote'
          }]
        }]
      }
    }]
  },
  qualifications: [
    "Problem solver and out of the box thinker.",
    "Self directed & Well Organized.",
    "Passionate about Distributed Systems, Automation, Metrics and Operational Excellence.",
    "Constantly looking to learn, experiment & grow.",
    "Experience working directly with customers.",
    "Studied Computer Science - Thompson Rivers University",
    "Languages: Java, Typescript, C#, PHP, Rust, Go",
  ],
  workSection: workSection,
  sections: [
    interestSection,
    personalProjectSection
  ],
  footer: {
    items: [{
      type: 'paragraph',
      text: "Created with React, versioned, containerized, CI/CD with Jenkins, metrics via: Grafana, loki and mimir"
    }, {
      type: 'link',
      text: 'resume.calebjonasson.com',
      url: 'https://resume.calebjonasson.com'
    }]
  }
} as ResumeModel;
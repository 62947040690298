import { ResumeSectionModel, WorkModel } from '../../definitions';
import { WorkSectionItemElement } from './WorkSectionItemElement';
import { TagRowElement } from './TagElement';
import { ElementProps } from './Elements';
import { IconRowElement } from './IconRowElement';
import './WorkSectionElement.css';

export interface WorkSectionElementProps extends ElementProps {
  work: WorkModel;
  section?: ResumeSectionModel;
}

export function WorkSectionElement(props: WorkSectionElementProps) {
  const listItems = props.work.items?.map(
    (item, index) => <WorkSectionItemElement key={index} workSectionItemElement={item} />
  );

  return (
    <div className='WorkSectionElement'>
      <div className='heading'>
        <div className={`icon display-block-md`}>
          {
            props.work.sectionIcon !== undefined && props.work.sectionIcon.length > 0 ?
              <img src={props.work.sectionIcon} alt={props.work.sectionIcon} /> : ''
          }
        </div>
        <div className='title-primary'>
          <h2 aria-label={props.section?.subSectionTitleLabel} className='text-primary'>{props.work.title}</h2>
          <h3 aria-label={props.section?.subSectionSubTitleLabel}>{props.work.subTitle}</h3>
          <meta name="company" content={props.work.title} />
        </div>
        <div className='title-secondary'>
          <div className='jobtitle' aria-label={props.section?.subSectionTitleDescriptionLabel}>{props.work.sectionDescription}</div>
          {/* <meta name="title" content={props.work.sectionDescription} /> */}
        </div>
        <div className='title-detail'>
          {props.work.startDate !== undefined ?
            <span className='text-accent'>{props.work.startDate}
              {
                props.work.endDate !== undefined ? <> - {props.work.endDate}</> : ''
              }
            </span> : ''}
        </div>
      </div>
      <div className='content'>
        <div className='items'>
          {listItems}
          <div className='clear' />
        </div>
      </div>
      <div className='footer'>
        <TagRowElement tags={props.work.tags ?? []} />
        <div className='image-row'>
          {props.work.footer?.imageRow !== undefined ?
            <IconRowElement icons={props.work.footer?.imageRow} /> : ''}
        </div>
      </div>
    </div >
  );
}
import { ResumeModel } from "../../definitions";
import { ContentElement } from "../../components/element/ContentElement";
import { IconRowElement } from "../../components/element/IconRowElement";
import { ResumeSectionElement } from "../../components/element/ResumeSectionElement";
import "./ResumePage.css";

export interface ResumePageProps {
  data: ResumeModel;
}
export function ResumePage(props: ResumePageProps) {

  const qualifications = props.data.qualifications
    .map((item, index) => <li key={index}>{item}</li>);
  const companyIcons = props.data.workSection.subsections
    .map(item => item.sectionIcon !== undefined && item.sectionIcon.length > 0 ?
      item.sectionIcon : '');

  const resumeSections = [props.data.workSection]
    .map((s, index) => <ResumeSectionElement key={index} resumeSection={s} />);

  return (
    <div className='ResumePage'>
      <header className="header">
        <div className='intro'>
          <div className='image'>
            <img src="assets/profile-picture.png" alt='' about="Profile picture Caleb Jonasson." />
          </div>

          <div className='content'>
            <ContentElement content={props.data.headingContent} />
          </div>
        </div>

        {/* Qualifications */}
        <div className='content'>
          <h3>Qualifications:</h3>
          <ul>
            {qualifications}
          </ul>
        </div>
        <IconRowElement icons={companyIcons} />
      </header>
      <div className="content">
        {resumeSections}
      </div>
    </div>
  );
}
import { ResumeSectionModel } from "../../definitions";
import { WorkSectionElement } from "./WorkSectionElement";
import "./ResumeSectionElement.css";

export function ResumeSectionElement(props: {resumeSection: ResumeSectionModel}) {
  const workModels = props.resumeSection.subsections.map(
    (item, index) => <WorkSectionElement key={index} work={item} />);
  return (
    <div className="ResumeSectionElement">
      <h2>{props.resumeSection.title}</h2>
      {workModels}
    </div>
  )
}
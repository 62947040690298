import { ResumeModel } from "../../definitions";
import { ResumeSectionElement } from "../../components/element/ResumeSectionElement";
import { SECTION_TITLE_PERSONAL_PROJECTS } from "../../data";

export interface ProjectsPageProps {
  data: ResumeModel;
}
export function ProjectsPage(props: ProjectsPageProps) {

  const resumeSections = [
    ...props.data.sections
  ].filter(s => s.title === SECTION_TITLE_PERSONAL_PROJECTS).map((s, index) => <ResumeSectionElement key={index} resumeSection={s} />);

  return <div>
    <div>
      <div className="content">
        {resumeSections}
      </div>
    </div>
  </div>;
}
import {
  Content,
  ContentContainerModel,
  ContentItemContent,
  ContentItemHeading,
  ContentItemLink,
  ContentItemParagraph,
  ContentItemTags,
  ContentItemText
} from "../../definitions";
import { ElementProps } from "./Elements";
import { TagRowElement } from "./TagElement";
import { ContainerElement } from "./ContainerElement";
import { TextElement } from "./InlineElement";

import "./ContentElement.css";

export function ContentItemElement (props: {content: Content}) {
  switch(props.content.type) {
    case 'link':
      return (
        <a href={(props.content as ContentItemLink).url}>
          {(props.content as ContentItemLink).text}
        </a>
      );
    case 'paragraph':
      return (
        <p>{(props.content as ContentItemParagraph).text}</p>
      )
    case 'heading':
      const c = props.content as ContentItemHeading;

      if(c.level === 1) {
        return <h1>{c.text}</h1>;
      } else if(c.level === 2) {
        return <h2>{c.text}</h2>;
      } else if(c.level === 3) {
        return <h3>{c.text}</h3>;
      } else if(c.level === 4) {
        return <h4>{c.text}</h4>;
      } else if(c.level === 5) {
        return <h5>{c.text}</h5>;
      } else if(c.level === 6) {
        return <h6>{c.text}</h6>;
      } else {
        return <h3>{c.text}</h3>
      }
    case 'content':
      const content = props.content as ContentItemContent;
      return (
        <ContentElement content={content.content} />
      )
    case 'tags':
      const tags = props.content as ContentItemTags;
      return (
        <TagRowElement tags={tags.tags} />
      )
    case 'container':
      const contentContainerModel = props.content as ContentContainerModel;
      return (
        <ContainerElement content={contentContainerModel} />
      )
    case 'text':
      const textModel = props.content as ContentItemText;
      return (
        <TextElement content={textModel}/>
      )
    case 'list':
      break;
    default:
      console.log("Error rendering content. Content type not recongnized.", props);
      return <></>;
  }
}


export interface ContentElementProps extends ElementProps {
  content?: ContentContainerModel;
}
export function ContentElement(props: {content: ContentContainerModel}) {
  const contentItems = props.content.items?.map(
    (c, index) => <ContentItemElement key={index} content={c} /> ?? []
  );
  return (
    <>
      {contentItems}
    </>
  )
}
import { ElementProps } from "./Elements";
import "./IconRowElement.css";

export interface IconRowElementProps extends ElementProps {
  icons: string[];
}
export function IconRowElement(props: IconRowElementProps) {
  const iconElements = props.icons
    .filter(v => v !== undefined && v.length > 0)
    .map(
      (i, index) => <img key={index} src={i} alt='company'/>
    );
  return (
    <div className={'IconRowElement'}>
      {iconElements}
    </div>
  )
}
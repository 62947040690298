import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResumeModel } from '../../definitions';
import './NavBar.css';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { TagElement } from '../element/TagElement';

export interface NavBarProps {
  resume: ResumeModel;
  version: string;
}

export function NavBar(props: NavBarProps) {
  return (
    <div className='NavBar'>
      <div className='title'>{props.resume.name}</div>
      <span className='NavBar-link'>
        {
          props.resume.links?.linkedin !== undefined ? <a href={props.resume.links?.linkedin}>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
            :
            ''
        }
        {
          props.resume.links?.github !== undefined ? <a href={props.resume.links?.github}>
            <FontAwesomeIcon icon={faGithub} />
          </a>
            :
            ''
        }
      </span>
      <TagElement tag={{ text: 'jest: 92%', type: 'tag' }} float={'right'} />
      <TagElement tag={{ text: 'v' + props.version, type: 'tag' }} float={'right'} />
      <div className='clear-float'></div>
    </div>
  );
}

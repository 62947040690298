// import {, CSS } from "react";
import { Property } from "csstype";
import { ContentItemTag } from "../../definitions";
import { ElementProps } from "./Elements";
import "./TagElement.css";

export interface TagElementProps extends ElementProps {
  tag: ContentItemTag;
  float?: Property.Float;
}

/**
 * The tag element that can be stylized.
 * @param props
 * @returns
 */
export function TagElement(props: TagElementProps) {
  return (
    <div style={{float: props.float ?? 'left' }} className={`TagElement ${props.styleClassName ?? ''}`}>{props.tag.text}</div>
  )
}

export interface TagRowElementProps extends ElementProps {
  tags: ContentItemTag[];
}

/**
 * A tag row
 * @param props
 * @returns
 */
export function TagRowElement(props: TagRowElementProps) {
  const tagElements = props.tags.filter(t => t !== undefined).map(
    (t, index) => <TagElement key={index} tag={t}/>);
  return (
    <div className="TagRowElement">
      {tagElements}
      <div className="clear"/>
    </div>
  )
}
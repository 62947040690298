import { ResumeModel } from "../../../definitions";
import { ContentElement } from "../../element/ContentElement";
import "./FooterComponent.css";

export interface FooterComponentProps {
  resumeModel: ResumeModel;
}

export const FooterComponent = (props: FooterComponentProps) => {
  return <div className="FooterComponent">
    {
      props.resumeModel.footer !== undefined ?
        <ContentElement content={props.resumeModel.footer} /> : ''
    }
  </div>;
};